<template>
  <b-row>
    <b-col md="12">
      <div v-if="invoice">
        <div class="mb-1">
          <h5 class="hide">Invoice ID:
            <span
              v-b-tooltip.hover.v-secondary="`Invoice ID: ${invoice.invoice_number}`"
            >{{ ' ' + invoice.invoice_number }}</span>
          </h5>
        </div>
        <div class="mb-1 d-flex flex-row">
          <!--  Work Period -->
          <div class="mr-1">
            {{ invoice.work_period }}
          </div>
          <!--  Payment Date -->
          <div class="mr-1">
            <span class="font-weight-bold">Payment Date:</span> {{ invoice.payment_date }}
          </div>
          <b-badge
            v-if="invoice.payment_status === 'Paid'"
            variant="success"
          >
            {{ invoice.payment_status }}
          </b-badge>
          <b-badge
            v-if="invoice.payment_status !== 'Paid'"
            variant="danger"
          >
            {{ invoice.payment_status }}
          </b-badge>
        </div>
      </div>
      <b-card-code
        title=""
        no-body
      >
        <b-card-body>

          <invoice-detail-table-header
            :invoice="invoice"
          />

          <b-tabs>
            <!-- summary -->
            <b-tab title="Summary">
              <!-- summary of contract type -->
              <invoice-summary-contract-table
                v-if="work_type == 'contract'"
                :items="summary"
              />
              <!-- summary of spot type -->
              <invoice-summary-spot-table
                v-if="work_type == 'spot'"
                :items="summary"
              />
              <!-- summary of tendered type -->
              <invoice-summary-tendered-table
                v-if="work_type == 'tendered'"
                :items="summary"
              />

            </b-tab>
            <!-- details -->
            <b-tab title="Details">
              <!-- details of contract type -->
              <invoice-details-contract-table
                v-if="work_type == 'contract'"
                :items="details"
              />
              <!-- details of spot type -->
              <invoice-details-spot-table
                v-if="work_type == 'spot'"
                :items="details"
              />
              <!-- details of tendered type -->
              <invoice-details-tendered-table
                v-if="work_type == 'tendered'"
                :items="details"
              />

            </b-tab>

          </b-tabs>
        </b-card-body>
      </b-card-code>
    </b-col>
  </b-row>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BCardBody, BRow, BCol, BBadge, BTabs, BTab, VBTooltip,
} from 'bootstrap-vue'

import * as paymentService from '@/services/payment'

import InvoiceDetailsContractTable from './InvoiceDetailsContractTable.vue'
import InvoiceSummaryContractTable from './InvoiceSummaryContractTable.vue'
import InvoiceSummarySpotTable from './InvoiceSummarySpotTable.vue'
import InvoiceDetailsSpotTable from './InvoiceDetailsSpotTable.vue'
import InvoiceSummaryTenderedTable from './InvoiceSummaryTenderedTable.vue'
import InvoiceDetailsTenderedTable from './InvoiceDetailsTenderedTable.vue'
import InvoiceDetailTableHeader from './InvoiceDetailTableHeader.vue'

export default {
  components: {
    BCardCode,
    BCardBody,
    BRow,
    BCol,
    BBadge,
    BTabs,
    BTab,
    InvoiceDetailsContractTable,
    InvoiceSummaryContractTable,
    InvoiceSummarySpotTable,
    InvoiceDetailsSpotTable,
    InvoiceSummaryTenderedTable,
    InvoiceDetailsTenderedTable,
    InvoiceDetailTableHeader,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      invoice: null,
      summary: [],
      details: [],
      work_type: '',
    }
  },
  created() {

  },
  mounted() {
    this.invoiceDetail()
  },
  methods: {
    invoiceDetail() {
      const invoiceID = this.$route.params.id
      this.$store.commit('set_loading_action', true)
      paymentService.invoiceDetail(invoiceID)
        .then(res => {
          this.$store.commit('set_loading_action', false)
          this.work_type = res.data.work_type
          this.invoice = res.data.invoice
          this.summary = res.data.summary
          this.details = res.data.details
        })
        .catch(err => {
          console.log(err)
          this.$store.commit('set_loading_action', false)
        })
    },
  },
}
</script>

<style>
    .hide {
        font-weight: 600;
        text-overflow: ellipsis;
        width: 150px;
        overflow: hidden;
        white-space: nowrap;
    }
</style>
