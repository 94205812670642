<template>
  <div>
    <div class="d-flex justify-content-end flex-wrap mb-2 px-2">

      <!-- filter -->
      <b-form-group>
                
        <b-input-group size="sm">
          <b-form-input
            id="filterInput"
            v-model="filter"
            type="search"
            placeholder="Type to Search"
          />
          <b-input-group-append>
            <b-button
              :disabled="!filter"
              @click="filter = ''"
            >
              Clear
            </b-button>
          </b-input-group-append>
        </b-input-group>

      </b-form-group>

    </div>

    <b-table
      striped
      hover
      responsive
      class="position-relative"
      :per-page="perPage"
      :current-page="currentPage"
      :items="items"
      :fields="fields"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      :filter="filter"
      :filter-included-fields="filterOn"
      @filtered="onFiltered"
    >
      <!--  Start and End Date -->
      <template #cell(start_end_date)="row">
        {{ row.item.start_date + ' - ' + row.item.end_date }}
      </template>

      <!--  Gross Pay -->
      <template #cell(gross_pay)="row">
        <span v-if="row.item.gross_pay">${{ row.item.gross_pay.toFixed(2) }}</span>
      </template>

      <!--  Base Rate -->
      <template #cell(base_rate)="row">
        <span v-if="row.item.base_rate">${{ row.item.base_rate.toFixed(2) }}</span>
      </template>

      <!--  Fuel Surcharge -->
      <template #cell(fuel_surcharge)="row">
        <span v-if="row.item.fuel_surcharge">${{ row.item.fuel_surcharge.toFixed(2) }}</span>
      </template>

      <!--  Tonu -->
      <template #cell(tonu)="row">
        <span v-if="row.item.tonu">${{ row.item.tonu.toFixed(2) }}</span>
      </template>

      <!--  Tolls -->
      <template #cell(tolls)="row">
        <span v-if="row.item.tolls">${{ row.item.tolls.toFixed(2) }}</span>
      </template>

      <!--  Detention -->
      <template #cell(detention)="row">
        <span v-if="row.item.detention">${{ row.item.detention.toFixed(2) }}</span>
      </template>

      <!--  Others -->
      <template #cell(others)="row">
        <span v-if="row.item.others">${{ row.item.others.toFixed(2) }}</span>
      </template>
    </b-table>

    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">

      <!-- page length -->
      <b-form-group
        label=""
        label-cols="6"
        label-align="left"
        label-size="sm"
        label-for="sortBySelect"
        class="text-nowrap mb-md-0 mr-1"
      >
        <b-form-select
          id="perPageSelect"
          v-model="perPage"
          size="sm"
          inline
          :options="pageOptions"
        />
      </b-form-group>

      <!-- pagination -->
      <div>
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
          class="mb-0"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </div>
    </b-card-body>
  </div>
</template>

<script>
import {
  BTable, BButton, BCardBody, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BInputGroupAppend,
} from 'bootstrap-vue'

export default {
  components: {
    BTable,
    BButton,
    BCardBody,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
  },
  props: {
    items: {
      type: Array,
      default() {
        return []
      },
    },
    tableType: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      fields: [
        { key: 'block_id', label: 'Block ID', sortable: true },
        { key: 'trip_id', label: 'Trip ID', sortable: true },
        { key: 'load_id', label: 'Load ID' },
        { key: 'item_type', label: 'Item Type' },
        { key: 'program_type', label: 'Program Type' },
        { key: 'start_end_date', label: ' Start/End Date ' },
        { key: 'route', label: 'Route/Domicile', sortable: true },
        { key: 'distance', label: 'Distance (Mi)' },
        { key: 'equipment', label: 'Equipment' },
        { key: 'gross_pay', label: 'Gross Pay' },
        { key: 'fuel_surcharge', label: 'Full Surcharge' },
        { key: 'base_rate', label: 'Base Rate' },
        { key: 'hourly_rate', label: 'Hourly Rate' },
        { key: 'duration', label: 'Duration (hrs)' },
        { key: 'variable_total', label: 'Variable Total' },
        { key: 'number_of_tractors', label: '# of Tractors' },
        { key: 'cost_per_tractor', label: 'Cost Per Tractor' },
        { key: 'detention', label: 'Detention' },
        { key: 'tonu', label: 'Tonu' },
        { key: 'tolls', label: 'Tolls' },
        { key: 'others', label: 'Others' },
        { key: 'comments', label: 'Comments' },
      ],
      perPage: 30,
      pageOptions: [30, 40, 50],
      totalRows: 1,
      currentPage: 1,
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
}
</script>
