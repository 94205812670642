<template>
  <div>
    <div class="d-flex justify-content-end flex-wrap mb-2 px-2">
      <!-- filter -->
      <b-form-group>
                
        <b-input-group size="sm">
          <b-form-input
            id="filterInput"
            v-model="filter"
            type="search"
            placeholder="Type to Search"
          />
          <b-input-group-append>
            <b-button
              :disabled="!filter"
              @click="filter = ''"
            >
              Clear
            </b-button>
          </b-input-group-append>
        </b-input-group>

      </b-form-group>

    </div>

    <b-table
      striped
      hover
      responsive
      class="position-relative"
      :per-page="perPage"
      :current-page="currentPage"
      :items="items"
      :fields="fields"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      :filter="filter"
      :filter-included-fields="filterOn"
      @filtered="onFiltered"
    >
      <!--  Block Rate -->
      <template #cell(block_rate)="row">
        ${{ row.item.block_rate ? row.item.block_rate.toFixed(2) : 0 }}
      </template>

      <!--  Accessorial -->
      <template #cell(accessorial)="row">
        ${{ row.item.accessorial ? row.item.accessorial.toFixed(2) : 0 }}
      </template>

      <!--  Paid Amount -->
      <template #cell(paidAmount)="row">
        ${{ row.item.paidAmount ? row.item.paidAmount.toFixed(2) : 0 }}
      </template>
            
    </b-table>

    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">

      <!-- page length -->
      <b-form-group
        label=""
        label-cols="6"
        label-align="left"
        label-size="sm"
        label-for="sortBySelect"
        class="text-nowrap mb-md-0 mr-1"
      >
        <b-form-select
          id="perPageSelect"
          v-model="perPage"
          size="sm"
          inline
          :options="pageOptions"
        />
      </b-form-group>

      <!-- pagination -->
      <div>
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
          class="mb-0"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </div>
    </b-card-body>
  </div>
</template>

<script>
import {
  BTable, BButton, BCardBody, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BInputGroupAppend,
} from 'bootstrap-vue'

export default {
  components: {
    BTable,
    BButton,
    BCardBody,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
  },
  props: {
    items: {
      type: Array,
      default() {
        return []
      },
    },
    tableType: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      fields: [
        { key: 'contract_id', label: 'Contract ID' },
        { key: 'calendar_week', label: 'Planned Week' },
        { key: 'operator_type', label: 'Driver Type' },
        { key: 'blocks', label: 'Blocks' },
        // { key: 'completed', label: 'Completed' },
        // { key: 'cancelled', label: 'Cancelled' },
        // { key: 'rejected', label: 'Rejected' },
        { key: 'block_rate', label: 'Block Rate' },
        { key: 'accessorial', label: 'Accessorial' },
        { key: 'paidAmount', label: 'Total' },
      ],
      perPage: 30,
      pageOptions: [30, 40, 50],
      totalRows: 1,
      currentPage: 1,
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
}
</script>
