<template>
  <div
    v-if="invoice"
    class="bg-light px-3 py-3 mb-1"
  >
    <div class="row">
      <div class="col-md-9 d-flex justify-content-start border-right">
        <h4>
          {{ invoice.work_type == 'SPOT' ? 'Load Board' : invoice.work_type == 'TENDERED' ? 'Direct Tendered' : 'Short Term Contract' }}<br>
          <b>${{ invoice.invoice_total ? invoice.invoice_total.toFixed(2) : 0 }}</b>
        </h4>
      </div>
      <div class="col-md-3 d-flex justify-content-end">
        <h4>
          Total Payout<br>
          <b>${{ invoice.invoice_total ? invoice.invoice_total.toFixed(2) : 0 }}</b>
        </h4>
      </div>
    </div>
  </div>
</template>

<script>
import { VBTooltip } from 'bootstrap-vue'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    invoice: {
      type: Object,
      default() {
        return null
      },
    },
  },
  mounted() {
  },
}
</script>

<style>

</style>
